/**
 * PrayersSanctuary 
 * www.santuariobasilicacoromoto.com
 * @author yadicksonvasquez@gmail.com
 * @updated 19/04/2020
 */

import React, { Component } from 'react';
import Header from './header';
import PrayersSanctuarySliders from './prayersSanctuarySliders';
import Footer from './footer';
import news1 from '../img/news/367_celebartion_319_252-small.jpg';
import news1Small from '../img/news/369-celebration-small.jpg';
import news2 from '../img/news/homilia-369-small.jpg';
import news3 from '../img/news/programa-370-virgen-coromoto.jpg';
import news4 from '../img/news/santa-misa-370-coromoto.jpg';
import news5 from '../img/news/programa372.jpg';
import news2Small from '../img/news/afiche-coromoto-small.jpg';
import { Helmet } from "react-helmet";
import { Trans } from '@lingui/macro';


export default class NewsHome extends Component {

    constructor(props) {
        super(props);
        let language = this.props.language != null ? this.props.language : (this.props.location.pathname.replace("/", "").includes("news-our-lady-coromoto.html") ? "en" : "es");
        let onLanguageChange = this.props.onLanguageChange != null ? this.props.onLanguageChange : null;
        if (sessionStorage.getItem('language') !== null && sessionStorage.getItem('language') !== 'null') {
            this.state = { lang: sessionStorage.getItem('language'), eventChangeLanguage: onLanguageChange };
        }
        else {
            this.state = { lang: language, eventChangeLanguage: onLanguageChange };
        }
        this.refreshLanguage = this.refreshLanguage.bind(this);
        this.invokeChangeLanguage = this.invokeChangeLanguage.bind(this);
    }

    componentDidMount() {
        if ((sessionStorage.getItem('language') !== null && sessionStorage.getItem('language') !== 'null') || (this.state.lang !== null && this.state.lang === 'en')) {
            if (this.state.eventChangeLanguage !== null && typeof this.state.eventChangeLanguage !== "undefined") {
                this.invokeChangeLanguage((sessionStorage.getItem('language') !== null && sessionStorage.getItem('language') !== 'null') ? sessionStorage.getItem('language') : this.state.lang);
            }
        }
    }

    invokeChangeLanguage(language) {
        if (this.state.eventChangeLanguage !== null && typeof this.state.eventChangeLanguage !== "undefined") {
            this.setState({ lang: language });
            sessionStorage.setItem('language', language);
            this.state.eventChangeLanguage(language);
        }
    }

    refreshLanguage(value) {
        if (value !== null && value !== '') {
            this.setState({ lang: value });
        }
    }

    render() {
        let helmetComp;
        if (this.state.lang === 'es') {
            //seo 
            helmetComp = <Helmet>
                <title>Noticas Basílica Menor Santuario Nacional Nuestra Señora de Coromoto</title>
                <meta name="description" content="Noticas Basílica Menor Santuario Nacional Nuestra Señora de Coromoto" />
                <meta name="keywords" content="noticias, santuario, virgen, coromoto, patrona, venezuela" />
            </Helmet>;
        }
        else {
            //seo 
            helmetComp = <Helmet>
                <title>News Minor Basilica National Sanctuary of Our Lady Of Coromoto</title>
                <meta name="description" content="News Minor Basilica National Sanctuary Our Lady of Coromoto" />
                <meta name="keywords" content="news, sanctuary, our, lady, coromoto" />
            </Helmet>;
        }


        return (<div>
            {helmetComp}
            <Header language={this.state.lang} onLanguageChange={this.state.eventChangeLanguage} sendData={this.refreshLanguage} />
            <PrayersSanctuarySliders />
            <div className="container">
                <div className="row">
                    <div className="col-12">&nbsp;</div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header"><h3><Trans>NewsResumeLb</Trans></h3>
                            </div>
                            <div className="card-body">

                                <div className="row">
                                    <div className="col-xl-6 col-lg-6 col-md-6 d-sm-none d-none d-md-block d-xl-block d-lg-block" align="center">
                                        <div className="container">
                                            <div className="row"><div className="col-12"><a href="programa-372-virgen-coromoto.html"><img src={news5} className="img-thumbnail" alt='Programa 372 Virgen de Coromoto' /></a></div></div>
                                            <div className="row"><div className="col-12">&nbsp;</div></div>
                                            <div className="row" align="left"><div className="col-12" style={{ "fontSize": "16px", "color": "#593D00", "fontWeight": "bold" }}>
                                                <a className="aNews" href="programa-372-virgen-coromoto.html"><Trans>News5Lb</Trans></a>
                                            </div></div>
                                            <div className="row" align="left"><div className="col-12" style={{ "fontSize": "14px", "color": "gray" }}><Trans>News5Resume</Trans></div></div>
                                        </div>
                                    </div>

                                    <div className="col-xl-6 col-lg-6 col-md-6 d-sm-none d-none d-md-block d-xl-block d-lg-block" align="center">
                                        <div className="container">
                                            <div className="row"><div className="col-12"><a href="programa-370-virgen-coromoto.html"><img src={news3} className="img-thumbnail" alt="Virgen de Coromoto" /></a></div></div>
                                            <div className="row"><div className="col-12">&nbsp;</div></div>
                                            <div className="row" align="left"><div className="col-12" style={{ "fontSize": "16px", "color": "#593D00", "fontWeight": "bold" }}>
                                                <a className="aNews" href="programa-370-virgen-coromoto.html"><Trans>News3Lb</Trans></a>
                                            </div></div>
                                            <div className="row" align="left"><div className="col-12" style={{ "fontSize": "14px", "color": "gray" }}><Trans>News3Resume</Trans></div></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-xl-6 col-lg-6 col-md-6 d-sm-none d-none d-md-block d-xl-block d-lg-block" align="center">
                                        <div className="container">
                                            <div className="row"><div className="col-12"><a href="homilia-369-aparicion-virgen-coromoto.html"><img src={news1} className="img-thumbnail" alt="Virgen de Coromoto" /></a></div></div>
                                            <div className="row"><div className="col-12">&nbsp;</div></div>
                                            <div className="row" align="left"><div className="col-12" style={{ "fontSize": "16px", "color": "#593D00", "fontWeight": "bold" }}>
                                                <a className="aNews" href="homilia-369-aparicion-virgen-coromoto.html"><Trans>News1Lb</Trans></a>
                                            </div></div>
                                            <div className="row" align="left"><div className="col-12" style={{ "fontSize": "14px", "color": "gray" }}><Trans>News1Resume</Trans></div></div>
                                        </div>
                                    </div>
                                    <div className="col-xl-6 col-lg-6 col-md-6 d-sm-none d-none d-md-block d-xl-block d-lg-block" align="center">
                                        <div className="container">
                                            <div className="row"><div className="col-12">
                                                <a className="aNews" href="celebracion-369-aparicion-virgen-coromoto.html"><img src={news2} className="img-thumbnail" alt="Virgen de Coromoto" /></a></div></div>
                                            <div className="row"><div className="col-12">&nbsp;</div></div>
                                            <div className="row" align="left"><div className="col-12" style={{ "fontSize": "16px", "color": "#593D00", "fontWeight": "bold" }}><a className="aNews" href="celebracion-369-aparicion-virgen-coromoto.html"><Trans>News2Lb</Trans></a></div></div>
                                            <div className="row" align="left"><div className="col-12" style={{ "fontSize": "14px", "color": "gray" }}><Trans>News2Resume</Trans></div></div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-12 d-sm-block d-none d-md-none d-xl-none d-lg-none" align="center">
                                        <div className="container">
                                            <div className="row"><div className="col-12">
                                                <a href="programa-370-virgen-coromoto.html"><img src={news3} className="img-thumbnail" alt="Virgen de Coromoto" /></a></div>
                                            </div>
                                            <div className="row"><div className="col-12">&nbsp;</div></div>
                                            <div className="row" align="left"><div className="col-12" style={{ "fontSize": "16px", "color": "#593D00", "fontWeight": "bold" }}>
                                                <a className="aNews" href="programa-370-virgen-coromoto.html"><Trans>News3Lb</Trans></a>
                                            </div></div>
                                            <div className="row" align="left"><div className="col-12" style={{ "fontSize": "14px", "color": "gray" }}><Trans>News3Resume</Trans></div></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12 d-sm-block d-none d-md-none d-xl-none d-lg-none" align="center">
                                        <div className="container">
                                            <div className="row"><div className="col-12">
                                                <a href="homilia-369-aparicion-virgen-coromoto.html"><img src={news1Small} className="img-thumbnail" alt="Virgen de Coromoto" /></a></div>
                                            </div>
                                            <div className="row"><div className="col-12">&nbsp;</div></div>
                                            <div className="row" align="left"><div className="col-12" style={{ "fontSize": "16px", "color": "#593D00", "fontWeight": "bold" }}>
                                                <a className="aNews" href="homilia-369-aparicion-virgen-coromoto.html"><Trans>News1Lb</Trans></a>
                                            </div></div>
                                            <div className="row" align="left"><div className="col-12" style={{ "fontSize": "14px", "color": "gray" }}><Trans>News1Resume</Trans></div></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12 d-sm-block d-none d-md-none d-xl-none d-lg-none" align="center">
                                        <div className="container">
                                            <div className="row"><div className="col-12"><img src={news2Small} className="img-thumbnail" alt="Virgen de Coromoto" /></div></div>
                                            <div className="row"><div className="col-12">&nbsp;</div></div>
                                            <div className="row" align="left"><div className="col-12" style={{ "fontSize": "16px", "color": "#593D00", "fontWeight": "bold" }}>
                                                <a className="aNews" href="celebracion-369-aparicion-virgen-coromoto.html"><Trans>News2Lb</Trans></a></div></div>
                                            <div className="row" align="left"><div className="col-12" style={{ "fontSize": "14px", "color": "gray" }}><Trans>News2Lb</Trans></div></div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-d-12 d-block d-sm-none d-md-none d-xl-none d-lg-none" align="center">
                                        <div className="container">
                                            <div className="row"><div className="col-12">
                                                <a href="programa-372-virgen-coromoto.html"><img src={news5} className="img-thumbnail" alt="Programa 372 Virgen de Coromoto" /></a></div>
                                            </div>
                                            <div className="row"><div className="col-12">&nbsp;</div></div>
                                            <div className="row" align="left"><div className="col-12" style={{ "fontSize": "16px", "color": "#593D00", "fontWeight": "bold" }}>
                                                <a className="aNews" href="programa-372-virgen-coromoto.html"><Trans>News5Lb</Trans></a>
                                            </div></div>
                                            <div className="row" align="left"><div className="col-12" style={{ "fontSize": "14px", "color": "gray" }}><Trans>News5Resume</Trans></div></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">&nbsp;</div>
                                <div className="row">
                                    <div className="col-12 d-sm-none d-md-none d-xl-none d-lg-none"><hr style={{ "height": "2px", "borderWidth": "0", "color": "gray", "backgroundColor": "#f3d593" }} width="100%" /></div>
                                </div>

                                <div className="row">
                                    <div className="col-d-12 d-block d-sm-none d-md-none d-xl-none d-lg-none" align="center">
                                        <div className="container">
                                            <div className="row"><div className="col-12">
                                                <a href="homilia-370-aparicion-virgen-coromoto.html"><img src={news4} className="img-thumbnail" alt="Virgen de Coromoto" /></a></div>
                                            </div>
                                            <div className="row"><div className="col-12">&nbsp;</div></div>
                                            <div className="row" align="left"><div className="col-12" style={{ "fontSize": "16px", "color": "#593D00", "fontWeight": "bold" }}>
                                                <a className="aNews" href="homilia-370-aparicion-virgen-coromoto.html"><Trans>News4Lb</Trans></a>
                                            </div></div>
                                            <div className="row" align="left"><div className="col-12" style={{ "fontSize": "14px", "color": "gray" }}><Trans>News4Resume</Trans></div></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">&nbsp;</div>
                                <div className="row">
                                    <div className="col-12 d-sm-none d-md-none d-xl-none d-lg-none"><hr style={{ "height": "2px", "borderWidth": "0", "color": "gray", "backgroundColor": "#f3d593" }} width="100%" /></div>
                                </div>

                                <div className="row">
                                    <div className="col-d-12 d-block d-sm-none d-md-none d-xl-none d-lg-none" align="center">
                                        <div className="container">
                                            <div className="row"><div className="col-12">
                                                <a href="programa-370-virgen-coromoto.html"><img src={news3} className="img-thumbnail" alt="Virgen de Coromoto" /></a></div>
                                            </div>
                                            <div className="row"><div className="col-12">&nbsp;</div></div>
                                            <div className="row" align="left"><div className="col-12" style={{ "fontSize": "16px", "color": "#593D00", "fontWeight": "bold" }}>
                                                <a className="aNews" href="programa-370-virgen-coromoto.html"><Trans>News3Lb</Trans></a>
                                            </div></div>
                                            <div className="row" align="left"><div className="col-12" style={{ "fontSize": "14px", "color": "gray" }}><Trans>News3Resume</Trans></div></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">&nbsp;</div>
                                <div className="row">
                                    <div className="col-12 d-sm-none d-md-none d-xl-none d-lg-none"><hr style={{ "height": "2px", "borderWidth": "0", "color": "gray", "backgroundColor": "#f3d593" }} width="100%" /></div>
                                </div>

                                <div className="row">
                                    <div className="col-d-12 d-block d-sm-none d-md-none d-xl-none d-lg-none" align="center">
                                        <div className="container">
                                            <div className="row"><div className="col-12">
                                                <a href="homilia-369-aparicion-virgen-coromoto.html"><img src={news1} className="img-thumbnail" alt="Virgen de Coromoto" /></a></div>
                                            </div>
                                            <div className="row"><div className="col-12">&nbsp;</div></div>
                                            <div className="row" align="left"><div className="col-12" style={{ "fontSize": "16px", "color": "#593D00", "fontWeight": "bold" }}>
                                                <a className="aNews" href="homilia-369-aparicion-virgen-coromoto.html"><Trans>News1Lb</Trans></a>
                                            </div></div>
                                            <div className="row" align="left"><div className="col-12" style={{ "fontSize": "14px", "color": "gray" }}><Trans>News1Resume</Trans></div></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">&nbsp;</div>
                                <div className="row">
                                    <div className="col-12 d-sm-none d-md-none d-xl-none d-lg-none"><hr style={{ "height": "2px", "borderWidth": "0", "color": "gray", "backgroundColor": "#f3d593" }} width="100%" /></div>
                                </div>
                                <div className="row">&nbsp;</div>
                                <div className="row">
                                    <div className="col-d-12 d-block d-sm-none d-md-none d-xl-none d-lg-none" align="center">
                                        <div className="container">
                                            <div className="row"><div className="col-12"><img src={news2} className="img-thumbnail" alt="Virgen de Coromoto" /></div></div>
                                            <div className="row"><div className="col-12">&nbsp;</div></div>
                                            <div className="row" align="left"><div className="col-12" style={{ "fontSize": "16px", "color": "#593D00", "fontWeight": "bold" }}>
                                                <a className="aNews" href="celebracion-369-aparicion-virgen-coromoto.html"><Trans>News2Lb</Trans></a></div></div>
                                            <div className="row" align="left"><div className="col-12" style={{ "fontSize": "14px", "color": "gray" }}><Trans>News2Lb</Trans></div></div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">&nbsp;</div>
                </div>




            </div>
            <Footer />
        </div>);
    }

}