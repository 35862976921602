/**
 * www.santuariobasilicacoromoto.com
 * @author yadicksonvasquez@gmail.com
 */

import React, { Component } from 'react';
import { Trans } from '@lingui/macro';
import header_logo_santuario_55 from '../img/Header_logo_santuario_55.png';

/**
 * Yadickson
 * @updated 12/02/2024
 */
export default class MenuExtraSmall extends Component {

    constructor(props) {
        super(props);
        let language = this.props.language != null ? this.props.language : "es";
        let onLanguageChange = this.props.onLanguageChange != null ? this.props.onLanguageChange : null;
        let refresh = this.props.refresh != null ? this.props.refresh : false;
        this.state = { lang: language, eventChangeLanguage: onLanguageChange, refreshComp: refresh };
    }

    changeLanguage(e, language) {
        e.preventDefault();
        if (this.state.eventChangeLanguage !== null && typeof this.state.eventChangeLanguage !== "undefined") {
            this.setState({ lang: language });

            //update language in parrent component
            this.props.updateL(language);

            sessionStorage.setItem('language', language);
            this.state.eventChangeLanguage(language);
            if (this.props.sendData !== null) {
                this.props.sendData(language);
            }
        }
    }

    render() {

        let HistoryOurLadyCoromotoPageURL, HistoryNationalSanctuaryPageURL, HistoryCathedralPageURL, FindingHolyRelicPageURL, PastoralLetterPageURL, KnowingHolyRelicPageURL, MisionPageURL,
            OrganizationPageURL, BishopPageURL, RectorPageURL, TimetablePageURL, SacramentsPageURL, LocationPageURL, DonationsPageURL, PrayersPageURL, VideosPageURL, MultimediaDownloadPageURL,
            TourSanctuaryPageURL, TourCathedralPageURL, TourMusseumPageURL, LinkPageURL, BlessedPageURL, ContactUsPageURL, SeminaryPageURL, venerablesVenezuelaURL, hnoNectarioMariaURL,
            newBasilicaMenorURL;

        if (this.state.lang === 'es') {
            HistoryOurLadyCoromotoPageURL = "historia-virgen-coromoto.html";
            HistoryNationalSanctuaryPageURL = "historia-santuario-nacional-virgen-coromoto.html";
            HistoryCathedralPageURL = "historia-catedral-guanare.html";
            FindingHolyRelicPageURL = "hallazgos-santa-reliquia-virgen-coromoto.html";
            PastoralLetterPageURL = "carta-pastoral-santa-reliquia-virgen-coromoto.html";
            KnowingHolyRelicPageURL = "santa-reliquia-virgen-coromoto.html";
            MisionPageURL = "mision-santuario-nacional-virgen-coromoto.html";
            OrganizationPageURL = "organigrama-santuario-nacional-virgen-coromoto.html";
            BishopPageURL = "obispo-diocesis-guanare.html";
            RectorPageURL = "rector-santuario-nacional-virgen-coromoto.html";
            TimetablePageURL = "horario-misa-santuario-nacional-virgen-coromoto.html";
            SacramentsPageURL = "sacramentos-santuario-nacional-virgen-coromoto.html";
            LocationPageURL = "ubicacion-santuario-virgen-coromoto.html";
            DonationsPageURL = "donaciones-santuario-nacional-virgen-coromoto.html";
            PrayersPageURL = "oraciones-virgen-de-coromoto.html";
            VideosPageURL = "videos-santuario-nacional-virgen-coromoto.html";
            MultimediaDownloadPageURL = "descargas-multimedia-santuario-nacional-virgen-coromoto.html";
            TourSanctuaryPageURL = "tour-virtual-santuario-nacional-virgen-coromoto.html";
            TourCathedralPageURL = "tour-virtual-catedral-guanare.html";
            TourMusseumPageURL = "museo-santuario-nacional-virgen-coromoto.html";
            LinkPageURL = "links.html";
            BlessedPageURL = "beatos-venezuela.html";
            ContactUsPageURL = "contactenos.html";
            SeminaryPageURL = "seminario-diocesano-nuestra-senora-coromoto.html";
            venerablesVenezuelaURL = "venerables-venezuela.html";
            hnoNectarioMariaURL = "hermano-nectario-maria.html";
            newBasilicaMenorURL = "noticias-santuario-virgen-coromoto.html";
        }
        else {
            if (this.state.lang === 'en') {
                HistoryOurLadyCoromotoPageURL = "history-our-lady-of-coromoto.html";
                HistoryNationalSanctuaryPageURL = "history-sanctuary-our-lady-of-coromoto.html";
                HistoryCathedralPageURL = "history-cathedral-guanare.html";
                FindingHolyRelicPageURL = "findings-holy-relic-our-lady-of-coromoto.html";
                PastoralLetterPageURL = "pastoral-letter-holy-relic-coromoto.html";
                KnowingHolyRelicPageURL = "knowing-holy-relic-our-lady-of-coromoto.html";
                MisionPageURL = "mission-basilica-minor-national-sanctuary-of-our-lady-of-coromoto.html";
                OrganizationPageURL = "organization-chart-sanctuary-our-lady-of-coromoto.html";
                BishopPageURL = "bishop-diocese-of-guanare.html";
                RectorPageURL = "rector-sanctuary-our-lady-of-coromoto.html";
                TimetablePageURL = "mass-schedule-minor-basilica-our-lady-coromoto.html";
                SacramentsPageURL = "sacraments-minor-basilica-our-lady-coromoto.html";
                LocationPageURL = "location-our-lady-of-coromoto.html";
                DonationsPageURL = "donations-sanctuary-our-lady-of-coromoto.html";
                PrayersPageURL = "prayers-to-our-lady-of-coromoto.html";
                VideosPageURL = "videos-our-lady-of-coromoto.html";
                MultimediaDownloadPageURL = "download-multimedia-our-lady-of-coromoto.html";
                TourSanctuaryPageURL = "tour-virtual-santuario-nacional-virgen-coromoto.html";
                TourCathedralPageURL = "tour-virtual-catedral-guanare.html";
                TourMusseumPageURL = "museo-santuario-nacional-virgen-coromoto.html";
                LinkPageURL = "links.html";
                BlessedPageURL = "blessed.html";
                ContactUsPageURL = "contact_us.html";
                SeminaryPageURL = "diocesan-seminary-our-lady-of-coromoto.html";
                venerablesVenezuelaURL = "venerables-venezuela.html";
                hnoNectarioMariaURL = "brother-nectario-maria.html";
                newBasilicaMenorURL = "news-our-lady-coromoto.html";
            }
        }



        return (
            <div>
                <a href="/" className="navbar-brand">
                    <img src={header_logo_santuario_55} alt="Basilica Menor Santuario Nacional de Nuestra Señora de Coromoto" />
                </a>
                <a href="index.html" className="navbar-brand" onClick={e => this.changeLanguage(e, "es")} style={{ "fontSize": "14px", "color": "#927f26", "fontWeight": "bold" }}>
                    ESP
                </a>
                <a href="index.html" className="navbar-brand" onClick={e => this.changeLanguage(e, "en")} style={{ "fontSize": "14px", "color": "#927f26", "fontWeight": "bold" }}>
                    ENG
                </a>

                <button className="navbar-toggler float-right" type="button" data-toggle="collapse" data-target="#navbar10">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="navbar-collapse collapse" id="navbar10">
                    <ul className="navbar-nav nav-fill w-100">
                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" href="/#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <span className="material-symbols-outlined">home</span> &nbsp;
                                <Trans>InitLb</Trans>
                            </a>
                            <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                <a className="dropdown-item" href="/"><Trans>InitPageLb</Trans></a>
                            </div>
                        </li>
                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" href="/#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <span class="material-symbols-outlined">church</span> &nbsp;
                                <Trans>SanctuaryLb</Trans>
                            </a>
                            <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                <a className="dropdown-item" href={HistoryOurLadyCoromotoPageURL}><Trans>HistoryOurLadyCoromotoPageLb</Trans></a>
                                <a className="dropdown-item" href={HistoryNationalSanctuaryPageURL}><Trans>HistorySanctuaryPageLb</Trans></a>
                                <a className="dropdown-item" href={HistoryCathedralPageURL}><Trans>HistoryCathedralPageLb</Trans></a>
                                <a className="dropdown-item" href={hnoNectarioMariaURL}><Trans>NectarioMariaBiographyLb</Trans></a>
                                <a className="dropdown-item" href={newBasilicaMenorURL}><Trans>NewsBasilicaLb</Trans></a>
                                <div className="dropdown-divider"></div>
                                <a className="dropdown-item" href={FindingHolyRelicPageURL}><Trans>FindingHolyRelicPageLb</Trans></a>
                                <a className="dropdown-item" href={PastoralLetterPageURL}><Trans>PastoralLetterPageLb</Trans></a>
                                <a className="dropdown-item" href={KnowingHolyRelicPageURL}><Trans>KnowingHolyRelicPageLb</Trans></a>
                                <div className="dropdown-divider"></div>
                                <a className="dropdown-item" href={MisionPageURL}><Trans>MisionPageLb</Trans></a>
                                <a className="dropdown-item" href={OrganizationPageURL}><Trans>OrganizationPageLb</Trans></a>
                                <a className="dropdown-item" href={BishopPageURL}><Trans>BishopPageLb</Trans></a>
                                <a className="dropdown-item" href={RectorPageURL}><Trans>RectorPageLb</Trans></a>
                                <a className="dropdown-item" href={SeminaryPageURL}><Trans>SeminaryPageLb</Trans></a>
                            </div>
                        </li>
                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" href="/#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <span class="material-symbols-outlined">info_i</span> &nbsp;
                                <Trans>InfoLb</Trans>
                            </a>
                            <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                <a className="dropdown-item" href={TimetablePageURL}><Trans>TimetablePageLb</Trans></a>
                                <a className="dropdown-item" href={SacramentsPageURL}><Trans>SacramentsPageLb</Trans></a>
                                <a className="dropdown-item" href={LocationPageURL}><Trans>LocationPageLb</Trans></a>
                                <a className="dropdown-item" href={DonationsPageURL}><Trans>DonationsPageLb</Trans></a>
                                <a className="dropdown-item" href={PrayersPageURL}><Trans>PrayersPageLb</Trans></a>
                            </div>
                        </li>
                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" href="/#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <span class="material-symbols-outlined">video_library</span> &nbsp;
                                <Trans>MultimediaLb</Trans>
                            </a>
                            <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                <a className="dropdown-item" href={VideosPageURL} ><Trans>VideosPageLb</Trans></a>
                                <a className="dropdown-item" href={MultimediaDownloadPageURL} ><Trans>MultimediaDownloadPageLb</Trans></a>
                                <a className="dropdown-item" href={TourSanctuaryPageURL} target="blank"><Trans>TourSanctuaryPageLb</Trans></a>
                                <a className="dropdown-item" href={TourCathedralPageURL} ><Trans>TourCathedralPageLb</Trans></a>
                                <a className="dropdown-item" href={TourMusseumPageURL} ><Trans>TourMusseumPageLb</Trans></a>
                            </div>
                        </li>
                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" href="/#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <span class="material-symbols-outlined">link</span> &nbsp;
                                <Trans>LinkLb</Trans>
                            </a>
                            <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                <a className="dropdown-item" href={LinkPageURL} ><Trans>LinkPageLb</Trans></a>
                                <a className="dropdown-item" href={BlessedPageURL} ><Trans>BlessedPageLb</Trans></a>
                                <a className="dropdown-item" href={venerablesVenezuelaURL} ><Trans>VenerablesPageLb</Trans></a>
                            </div>
                        </li>
                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" href="/#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <span class="material-symbols-outlined">contacts</span> &nbsp;
                                <Trans>ContactUsLb</Trans>
                            </a>
                            <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                <a className="dropdown-item" href={ContactUsPageURL} ><Trans>ContactUsPageLb</Trans></a>
                            </div>
                        </li>
                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" href="/#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <span class="material-symbols-outlined">translate</span> &nbsp;
                                <Trans>languageLb</Trans>
                            </a>
                            <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                <a className="dropdown-item" href="index.html" onClick={e => this.changeLanguage(e, "es")}><Trans>SpanishLb</Trans></a>
                                <a className="dropdown-item" href="index.html" onClick={e => this.changeLanguage(e, "en")}><Trans>EnglishLb</Trans></a>
                            </div>
                        </li>
                    </ul>
                </div>

            </div>
        );
    }

}