/**
 * ProgramCelebration372 
 * www.santuariobasilicacoromoto.com
 * @author yadicksonvasquez@gmail.com
 * @updated 27/08/2024
 */

import React, { Component } from 'react';
import Header from './header';
import Footer from './footer';
import { Helmet } from "react-helmet";
import { Trans } from '@lingui/macro';
import scheduleImage from '../img/news/cronograma.png';
import PrayersSanctuarySliders from './prayersSanctuarySliders';

export default class ProgramCelebration372 extends Component {

    constructor(props) {
        super(props);
        let language = this.props.language != null ? this.props.language : (this.props.location.pathname.replace("/", "").includes("schedule-372-virgen-coromoto.html") ? "en" : "es");
        let onLanguageChange = this.props.onLanguageChange != null ? this.props.onLanguageChange : null;
        if (sessionStorage.getItem('language') !== null && sessionStorage.getItem('language') !== 'null') {
            this.state = { lang: sessionStorage.getItem('language'), eventChangeLanguage: onLanguageChange };
        }
        else {
            this.state = { lang: language, eventChangeLanguage: onLanguageChange };
        }
        this.refreshLanguage = this.refreshLanguage.bind(this);
        this.invokeChangeLanguage = this.invokeChangeLanguage.bind(this);
    }

    componentDidMount() {
        if ((sessionStorage.getItem('language') !== null && sessionStorage.getItem('language') !== 'null') || (this.state.lang !== null && this.state.lang === 'en')) {
            if (this.state.eventChangeLanguage !== null && typeof this.state.eventChangeLanguage !== "undefined") {
                this.invokeChangeLanguage((sessionStorage.getItem('language') !== null && sessionStorage.getItem('language') !== 'null') ? sessionStorage.getItem('language') : this.state.lang);
            }
        }
    }

    invokeChangeLanguage(language) {
        if (this.state.eventChangeLanguage !== null && typeof this.state.eventChangeLanguage !== "undefined") {
            this.setState({ lang: language });
            sessionStorage.setItem('language', language);
            this.state.eventChangeLanguage(language);
        }
    }

    refreshLanguage(value) {
        if (value !== null && value !== '') {
            this.setState({ lang: value });
        }
    }

    render() {

        let helmetComp;
        if (this.state.lang === 'es') {
            //seo 
            helmetComp = <Helmet>
                <title>Programación de las fiestas Patronales 372 años de la aparición de la Virgen Santísima María de Coromoto</title>
                <meta name="description" content="Programa de las fiestas con motivo de los 372 a&ntilde;os de la Aparici&oacute;n de la Sant&iacute;sima Virgen de Coromoto" />
                <meta name="keywords" content="programa, 372, coromoto, virgen, venezuela" />
            </Helmet>;
        }
        else {
            //seo 
            helmetComp = <Helmet>
                <title>Program of the parties on the occasion of the 372 years of the Apparition of Our Lady of Coromoto</title>
                <meta name="description" content="Program of the parties on the occasion of the 372 years of the Apparition of the Blessed Virgin of Coromoto" />
                <meta name="keywords" content="program, 372, coromoto, virgen, venezuela" />
            </Helmet>;
        }

        return (

            <div>
                {helmetComp}
                <Header language={this.state.lang} onLanguageChange={this.state.eventChangeLanguage} sendData={this.refreshLanguage} />
                <PrayersSanctuarySliders />
                <div className="container">
                    <div className="row">
                        <div className="col-12">&nbsp;</div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header"><strong><Trans>Program372Lb</Trans></strong></div>
                                <div className="card-body">

                                    <div className="row">
                                        <div className="col-12">
                                            <article>
                                                <p style={{ "text-align": "justify" }}><Trans>Program372P1</Trans></p>
                                            </article>
                                        </div>
                                    </div>
                                    <div className="row" align="center">
                                        <div className="col-md-12 d-none d-md-block d-lg-block d-xl-block">
                                            <iframe width="550" height="390" src="https://www.youtube.com/embed/epl9qgoCNpI?autoplay=0"
                                                allowfullscreen="allowfullscreen" title="Historia de la Aparición de la Virgen de Coromoto" allow="accelerometer;  clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">&nbsp;</div>
                                    </div>
                                    <div className="row" align="center">
                                        <div className="col-12"><a href={scheduleImage}><img src={scheduleImage} className="img-thumbnail" alt="Programa 372 Virgen Coromoto" /></a></div>
                                    </div>

                                    <div className="row">
                                        <div className="col-12">&nbsp;</div>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">&nbsp;</div>
                    </div>




                </div>
                <Footer />
            </div>

        );
    }

}