/**
 * NewsComponentResume 
 * www.santuariobasilicacoromoto.com
 * @author yadicksonvasquez@gmail.com
 * @updated 04/10/2021
 */

import React, { Component } from 'react';
import news5 from '../img/news/Programa.jpg';
import news1Small from '../img/news/369-celebration-small.jpg';
import news2Small from '../img/news/afiche-coromoto-small.jpg';
import { Trans } from '@lingui/macro';
import imageNews4 from '../img/news/homilia-370-aparicion-virgen-coromoto-small.jpg';

export default class NewsResumeComponent extends Component {

    constructor(props) {
        super(props);
        let language = this.props.language != null ? this.props.language : "es";
        let onLanguageChange = this.props.onLanguageChange != null ? this.props.onLanguageChange : null;
        if (sessionStorage.getItem('language') !== null) {
            this.state = { lang: sessionStorage.getItem('language'), eventChangeLanguage: onLanguageChange };
        }
        else {
            this.state = { lang: language, eventChangeLanguage: onLanguageChange };
        }
        this.refreshLanguage = this.refreshLanguage.bind(this);
    }

    componentDidMount() {
        if (sessionStorage.getItem('language') !== null) {
            if (this.state.eventChangeLanguage !== null && typeof this.state.eventChangeLanguage !== "undefined") {
                this.setState({ lang: sessionStorage.getItem('language') });
                sessionStorage.setItem('language', sessionStorage.getItem('language'));
                this.state.eventChangeLanguage(sessionStorage.getItem('language'));
            }
        }
    }

    refreshLanguage(value) {
        if (value !== null && value !== '') {
            this.setState({ lang: value });
        }
    }

    render() {
        return (<div>
            <div className="row">
                <div className="col-lg-12">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-12 col-lg-12 col-md-12 d-sm-none d-none d-md-block d-xl-block d-lg-block"
                                style={{ "fontSize": "22px", "color": "#593D00", "fontWeight": "bold" }} align="left"><Trans>NewsResumeLb</Trans></div>
                        </div>
                        <div className="row">
                            <div className="col-xl-12 col-lg-12 col-md-12 d-sm-none d-none d-md-block d-xl-block d-lg-block" ><hr style={{ "height": "2px", "borderWidth": "0", "color": "gray", "backgroundColor": "#f3d593" }} width="100%" /></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12 d-sm-block d-none d-md-none d-xl-none d-lg-none"
                                style={{ "fontSize": "22px", "color": "#593D00", "fontWeight": "bold" }} align="left"><Trans>NewsResumeLb</Trans></div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12 d-sm-block d-none d-md-none d-xl-none d-lg-none" ><hr style={{ "height": "2px", "borderWidth": "0", "color": "gray", "backgroundColor": "#f3d593" }} width="100%" /></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-6 d-sm-none d-none d-md-block d-xl-block d-lg-block" align="center">
                    <div className="container">
                        <div className="row"><div className="col-12"><a href="programa-372-virgen-coromoto.html"><img src={news5} className="img-thumbnail" alt='Programa 372 Virgen de Coromoto'/></a></div></div>
                        <div className="row"><div className="col-12">&nbsp;</div></div>
                        <div className="row" align="left"><div className="col-12" style={{ "fontSize": "16px", "color": "#593D00", "fontWeight": "bold" }}>
                            <a className="aNews" href="programa-372-virgen-coromoto.html"><Trans>News5Lb</Trans></a>
                        </div></div>
                        <div className="row" align="left"><div className="col-12" style={{ "fontSize": "14px", "color": "gray" }}><Trans>News5Resume</Trans></div></div>
                    </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 d-sm-none d-none d-md-block d-xl-block d-lg-block" align="center">
                    <div className="container">
                        <div className="row"><div className="col-12"><a href="homilia-370-aparicion-virgen-coromoto.html"><img src={imageNews4} className="img-thumbnail" alt='Virgen de Coromoto'/></a></div></div>
                        <div className="row"><div className="col-12">&nbsp;</div></div>
                        <div className="row" align="left"><div className="col-12" style={{ "fontSize": "16px", "color": "#593D00", "fontWeight": "bold" }}>
                            <a className="aNews" href="homilia-370-aparicion-virgen-coromoto.html"><Trans>News4Lb</Trans></a>
                        </div></div>
                        <div className="row" align="left"><div className="col-12" style={{ "fontSize": "14px", "color": "gray" }}><Trans>News4Resume</Trans></div></div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-6 d-sm-block d-none d-md-none d-xl-none d-lg-none" align="center">
                    <div className="container">
                        <div className="row"><div className="col-12">
                            <a href="homilia-369-aparicion-virgen-coromoto.html"><img src={news1Small} className="img-thumbnail" alt='Virgen de Coromoto'/></a></div>
                        </div>
                        <div className="row"><div className="col-12">&nbsp;</div></div>
                        <div className="row" align="left"><div className="col-12" style={{ "fontSize": "16px", "color": "#593D00", "fontWeight": "bold" }}>
                            <a className="aNews" href="homilia-369-aparicion-virgen-coromoto.html"><Trans>News1Lb</Trans></a>
                        </div></div>
                        <div className="row" align="left"><div className="col-12" style={{ "fontSize": "14px", "color": "gray" }}><Trans>News1Resume</Trans></div></div>
                    </div>
                </div>
                <div className="col-sm-6 d-sm-block d-none d-md-none d-xl-none d-lg-none" align="center">
                    <div className="container">
                        <div className="row"><div className="col-12"><img src={news2Small} className="img-thumbnail" alt='Virgen de Coromoto'/></div></div>
                        <div className="row"><div className="col-12">&nbsp;</div></div>
                        <div className="row" align="left"><div className="col-12" style={{ "fontSize": "16px", "color": "#593D00", "fontWeight": "bold" }}>
                            <a className="aNews" href="celebracion-369-aparicion-virgen-coromoto.html"><Trans>News2Lb</Trans></a></div></div>
                        <div className="row" align="left"><div className="col-12" style={{ "fontSize": "14px", "color": "gray" }}><Trans>News2Lb</Trans></div></div>
                    </div>
                </div>
            </div>
        </div>);
    }

}